<template>
  <v-container class="main">
    <v-data-table
      :headers="headers"
      :items="guests"
      :items-per-page="20"
      class="elevation-1"
    ></v-data-table>
    <h1 class="ma-15">אורחים: {{count}}</h1>
  
  </v-container>
</template>

<script>
export default {
  name: "Main",
  mounted: function () {
    this.axios
      .get("/guests")
      .then((response) => {
        // Handle success.
        console.log(response.data);
        let data = response.data;
        this.guests = data;
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          this.count += item.Guests;
        }
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
      });
  },
  data() {
    return {
      count: 0,
      headers: [
        { text: "מגיע", value: "Coming" },
        { text: "אורחים", value: "Guests" },
        { text: "נייד", value: "Cell" },
        { text: "משפחה", value: "Surname" },
        { text: "שם", value: "Name" },
      ],
      guests: [],
    };
  },
  methods: {},
};
</script>
<style lang="scss">
</style>
